import { FeatureFlagOptions } from '@core/components/app/features/features'
import { ClientVariables } from '@core/components/app/features/variables'
import { countryCodes, makeCountriesList } from '@core/shared/data/countryCodes'

const features: FeatureFlagOptions = {
  articles: true,
  servicesNearYou: true,
  dependants: true,
  messageGp: false,
  gpPharmacyMap: true,
  clientIdentifierPin: false,
  clientIdentifierField: false,
  confirmEmailAddress: false,
  clientIdentifierToSAPI: false,
  hasSymptomsField: true,
  symptomAssessment: true,
  isPharmacyRequired: false,
  hasStandardAuth: true,
  hasStandardBookingEntry: true,
  bookings: {
    services: {
      value: {
        Counsellor: {
          isEnabled: true,
          bookingTypes: {
            online: true,
            phone: true,
          },
        },
        GeneralPractitioner: {
          isEnabled: true,
          bookingTypes: {
            online: true,
            phone: true,
          },
        },
        Physiotherapist: {
          isEnabled: true,
          bookingTypes: {
            online: true,
            phone: true,
          },
        },
        SecondOpinion: {
          isEnabled: true,
          bookingTypes: {
            online: true,
            phone: true,
          },
        },
      },
    },
  },
}

const clientVariables: ClientVariables = {
  clientVariables: {
    phoneNumber: '0123 456 7890',
    phoneInputPlaceholder: 'e.g. +4401234567890',
    clientIdentifierLabel: 'Client code',
    countryList: makeCountriesList(countryCodes, ['GB', 'IE']),
    baseCountryCode: 'GB',
    clientName: 'NextGen',
  },
  dashboardVariables: {
    logoSmallWidth: 70,
    logoLargeWidth: 56,
    logoAlt: 'HealthHero',
    specialtiesLayout: 'MultipleNoImage',
    hasBackground: true,
  },
  landingVariables: {
    backgroundStyles: {
      borderRadius: '0 0 80px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    landingSlider: {
      numSlides: 2,
      slide2Content: [
        'Unlimited access to experienced GPs',
        'Private prescription, or a open referral letters',
        'Support from a multidisciplinary clinician team',
        'Experienced GPs, Clinical Pharmacists, Mental Health Advisors and MSK Advisors',
      ],
    },
  },
}

export const getFeatures = () => features

export const getClientVariables = () => clientVariables
