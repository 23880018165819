import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Conclusion, ConclusionBullet } from '@doctorlink/traversal-core'
import {
  CollapsiblePanel,
  VariantEnum,
} from '@core/components/CollapsiblePanel/CollapsiblePanel'
import { InfoModal } from './InfoModal'

type Props = {
  title: string
  bullets: ConclusionBullet[] | Conclusion[]
  type?: keyof typeof VariantEnum
  messageLevel: number
}

export const ReportPanel: React.FC<Props> = (props) => {
  const { title, bullets, type, messageLevel } = props

  if (!bullets.length) {
    return null
  }

  return (
    <CollapsiblePanel
      title={title}
      type={type === 'alert' ? 'alert' : undefined}
      isOpen={true}
    >
      <ul>
        {bullets.map(
          (bullet: Conclusion | ConclusionBullet, key: React.Key) => {
            return (
              <li
                key={key}
                data-title={bullet.category2 === 'Bullet: title' && 'true'}
              >
                {ReactHtmlParser(bullet.displayText)}
                <InfoModal
                  explanation={bullet.explanation}
                  messageLevel={messageLevel}
                  key={bullet.assetId}
                  id={bullet.assetId}
                />
              </li>
            )
          },
        )}
      </ul>
    </CollapsiblePanel>
  )
}
