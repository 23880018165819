import {
  AppConfig,
  baseAppConfigDevelopment,
  baseAppConfigUAT,
  baseAppConfigProduction,
} from '@core/shared/configuration'

const recaptchaSiteKeyLive = '6Lft5YMcAAAAAEJ8k3lVq6dfUPHGav7rShpeka-1'
const recaptchaSiteKeyUAT = '6LfzdOUUAAAAAMhj7t419rm7o9tp49gJlAkona_W'

const appConfig: AppConfig = {
  development: {
    ...baseAppConfigDevelopment,
    recaptcha: {
      siteKey: recaptchaSiteKeyUAT,
    },
  },
  uat: {
    ...baseAppConfigUAT,
    api: {
      ...baseAppConfigUAT.api,
      clientId: '2',
    },
    auth: {
      ...baseAppConfigUAT.auth,
      clientId: 'MSAPI',
      clientSecret: 'MSAPISecret',
    },
    recaptcha: {
      siteKey: recaptchaSiteKeyUAT,
    },
    clientURL: 'https://nextgen-test.azurewebsites.net',
  },
  production: {
    ...baseAppConfigProduction,
    api: {
      ...baseAppConfigProduction.api,
      clientId: '845',
    },
    auth: {
      ...baseAppConfigProduction.auth,
      clientId: '845.NextGenLive',
      clientSecret: '41b46b53-4edf-8bc1-b97d-63f6ed2a3b81',
    },
    recaptcha: {
      siteKey: recaptchaSiteKeyLive,
    },
    clientURL: 'https://nextgen-live.azurewebsites.net',
  },
}

export default appConfig
