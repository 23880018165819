import React from 'react'
import { Conclusion } from '@doctorlink/traversal-core'
import { VariantEnum } from '@core/components/CollapsiblePanel/CollapsiblePanel'
import { Card } from '@core/components/card/Card'
import { InfoModal } from './InfoModal'
import styles from './OtherConclusions.module.scss'

interface Props {
  title: string
  bullets: Conclusion[]
  severity: keyof typeof VariantEnum
  messageLevel: number
}

export const OtherConlusions: React.FC<Props> = (props) => {
  const { title, bullets, severity, messageLevel } = props

  if (!bullets.length) {
    return null
  }

  return (
    <>
      <h3 className="section-title mb-1">{title}</h3>
      <Card className={severity}>
        <ul className={styles.conclusions}>
          {bullets.map((conclusion) => {
            return (
              <li key={conclusion.assetId}>
                {conclusion.displayText}
                <InfoModal
                  explanation={conclusion.explanation}
                  messageLevel={messageLevel}
                  key={conclusion.assetId}
                  id={conclusion.assetId}
                />
              </li>
            )
          })}
        </ul>
      </Card>
    </>
  )
}
