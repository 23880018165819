import { ReactNode, useState } from 'react'
import PaymentEligibilityContext from './PaymentEligibilityContext'

interface ProviderProps {
  children: ReactNode
}

export const PaymentEligibilityProvider = ({ children }: ProviderProps) => {
  const [IsPaymentEligibilityEnabled, setIsPaymentEligibilityEnabled] =
    useState(false)
  const value = {
    IsPaymentEligibilityEnabled,
    setIsPaymentEligibilityEnabled,
  }

  return (
    <PaymentEligibilityContext.Provider value={value}>
      {children}
    </PaymentEligibilityContext.Provider>
  )
}
