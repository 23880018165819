import { useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import { useAction } from '@core/shared/useAction'
import { logout as logoutAction } from '@core/app/auth/actions'
import { getPaymentConfiguration } from '@core/api/paymentEligibility'
import { usePaymentEligibilityWrapper } from './usePaymentEligibilityWrapper'

const enablePayments = async () => {
  const configuration = await getPaymentConfiguration()
  if (configuration.exists && configuration.paymentMethods.includes('Psp')) {
    return true
  }
  return false
}

export const usePaymentEligibility = () => {
  const { setIsPaymentEligibilityEnabled } = usePaymentEligibilityWrapper()
  const logOut = useAction(logoutAction)

  useEffect(() => {
    async function checkEligibility() {
      try {
        // Uncomment once PE wrapper is ready for production
        // const enabled = await enablePayments()
        // setIsPaymentEligibilityEnabled(enabled)
        setIsPaymentEligibilityEnabled(false)
      } catch (error) {
        datadogLogs.logger.error('Failed to check eligibility endpoint')
        logOut() // should log you out once endpoint is ready
      }
    }
    checkEligibility()
  }, [])
}
