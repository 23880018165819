import { usePaymentEligibility } from './usePaymentEligibility'

type Props = {
  children: JSX.Element
}
export const PaymentEligibilityContainer = (props: Props): JSX.Element => {
  const { children } = props
  usePaymentEligibility()
  return children
}
