import { FunctionComponent, useEffect, useState } from 'react'

import { GeneralLayout } from '@core/components/layouts/general'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { SubmitBookingType } from '@core/shared/types/bookings'
import { submitBookingAction } from '@core/app/bookings/actions'
import { useAction, useActionAsync } from '@core/shared/useAction'
import { selectAppUser } from '@core/app/user/selectors'
import { usePaymentEligibilityWrapper } from '@core/components/app/paymentEligibility/usePaymentEligibilityWrapper'
import { trackGoal } from '@core/shared/utils/analytics/useMatomo'
import { GenericError } from '@core/components/genericError'
import {
  clearBookingFromSessionStorage,
  readBookingFromSessionStorage,
} from '@core/shared/utils/bookingSessionStorage'
import { InlineLoader } from '@core/components/app/InlineLoader'
import { useQuery } from '@core/shared/utils/useQuery'
import styles from './PaymentConfirmation.module.scss'
import { useFeatures } from '@core/components/app/features/features'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'

const makeSubmitPayload = (booking: Record<string, any>) => {
  const submitData: SubmitBookingType = {
    files: booking.files || [],
    data: {
      type: 'bookings',
      attributes: {
        symptoms: booking.symptoms ? [booking.symptoms] : null,
        reasonForBooking: booking.reasonForBooking,
        contactDetails: {
          phoneNumber: booking.contactDetails.phoneNumber,
          address: booking.contactDetails.address,
        },
        consultationType: booking.consultationType,
      },
      relationships: {
        appointment: {
          data: {
            type: 'appointments',
            id: booking.appointmentSlotId,
          },
        },
      },
    },
  }

  if (booking.patient.isDependant) {
    submitData.data.relationships.dependant = {
      data: {
        type: booking.patient.type,
        id: booking.patient.id,
      },
    }
  }

  return submitData
}

export const PaymentConfirmationPage: FunctionComponent = () => {
  const [paymentError, setPaymentError] = useState(false)
  const user = useSelector(selectAppUser)
  const history = useHistory()
  const submitBooking = useActionAsync(submitBookingAction)
  const { IsPaymentEligibilityEnabled } = usePaymentEligibilityWrapper()
  const query = useQuery()
  const redirect_status = query.get('redirect_status')
  const { bookings } = useFeatures()
  const canUploadSupportingDocuments =
    bookings.uploadSupportingDocuments.isEnabled
  const showNotification = useAction(showNotificationAction)

  const submit = async () => {
    if (!user) {
      setPaymentError(true)
      return
    }

    const booking = readBookingFromSessionStorage()

    if (!booking) {
      // we don't have booking information
      setPaymentError(true)
      return
    }
    const data = makeSubmitPayload(booking)

    try {
      await submitBooking({
        userId: user.id,
        data,
        IsPaymentEligibilityEnabled,
      })
      trackGoal(2) // GP booking
      clearBookingFromSessionStorage()

      if (canUploadSupportingDocuments) {
        // Give the user the chance to upload any supporting documents
        // now that their booking has been confirmed.
        history.push(
          ScreenRoutes.BOOKING_COMPLETED.replace(
            ':bookingId',
            booking.appointmentSlotId,
          ),
        )
      } else {
        showNotification({
          title: 'Booking confirmed',
          type: 'success',
        })
        history.push(ScreenRoutes.DASHBOARD)
      }
    } catch (e) {
      console.error(e)
      setPaymentError(true)
      clearBookingFromSessionStorage()
    }
  }

  useEffect(() => {
    switch (redirect_status) {
      case 'succeeded':
        submit()
        break
      case 'failed':
        setPaymentError(true)
        break
      default:
        setPaymentError(true)
        break
    }
  }, [redirect_status])

  return (
    <GeneralLayout title="Book your consultation">
      <section className="section">
        {paymentError ? (
          <GenericError />
        ) : (
          <div className={styles.paymentConfirmation}>
            <h2 className="text-heading-2 mb-2">Confirming your booking</h2>
            <span>
              <InlineLoader />
            </span>
          </div>
        )}
      </section>
    </GeneralLayout>
  )
}
