import React from 'react'
import styles from './Modal.module.scss'
import { CloseIcon } from '../icons/CloseIcon'

type Props = {
  title: string
  children: React.ReactNode
  toggleModal: any
}

export const Modal: React.FC<Props> = (props) => {
  const { title, toggleModal } = props

  return (
    <div
      role="alertdialog"
      aria-modal="true"
      className={styles.modalWrapper}
      aria-labelledby={title}
      onClick={toggleModal}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <header>
          <h2>{title}</h2>
          <button className={styles.closeButton} onClick={toggleModal}>
            <CloseIcon />
          </button>
        </header>
        {props.children}
      </div>
    </div>
  )
}
