import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import cx from 'clsx'

import { Conclusion } from '@doctorlink/traversal-core'
import { InfoModal } from './InfoModal'
import styles from './ConclusionsPanel.module.scss'

interface Props {
  title: string
  conclusions: Conclusion[]
  messageLevel: number
  otherConclusion?: boolean
}

export const ConclusionsPanel: React.FC<Props> = (props) => {
  const { conclusions, title, messageLevel, otherConclusion = false } = props

  if (!conclusions?.length) {
    return null
  }

  const componentStyles = otherConclusion
    ? cx('card', styles.otherConclusions)
    : cx(styles.conclusions)

  return (
    <div className="section" data-testid="results">
      <h3 className="section-title mb-1">{title}</h3>
      <ul className={componentStyles}>
        {conclusions.map((conclusion) => {
          return (
            <li key={conclusion.assetId}>
              {ReactHtmlParser(conclusion.displayText)}
              <InfoModal
                explanation={conclusion.explanation}
                messageLevel={messageLevel}
                key={conclusion.assetId}
                id={conclusion.assetId}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
