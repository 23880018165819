import { memo } from 'react'
import cx from 'clsx'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import logo from '@core/styles/images/HealthHeroLogo.svg'

type Props = {
  text: {
    pageTitle: string
    paragraphs: Array<string>
    privacyLink?: {
      label?: string
      href?: string
    }
    termsLink?: {
      label?: string
      href?: string
    }
  }
}

export const AboutTemplate = memo(function AboutTemplate({ text }: Props) {
  const linkItems = [
    {
      label: text.privacyLink?.label || 'Read our Privacy Policy',
      href:
        text.privacyLink?.href || 'https://www.healthhero.com/privacy-policy/',
    },
    {
      label: text.termsLink?.label || 'Read our Terms and Conditions',
      href:
        text.termsLink?.href ||
        'https://www.healthhero.com/terms-and-conditions/',
    },
  ]

  return (
    <div className="page about-page">
      <PageHeader showBg backBtn title="About" />

      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="section">
            <div className="logo-wrapper mt-2 mb-2 flex-center">
              <img src={logo} alt="HealthHero logo" />
            </div>
          </div>

          <div className="section">
            <h3 className="paper-header text-left about-page-title">
              {text.pageTitle}
            </h3>

            {text.paragraphs.map((paragraph: string, index: number) => (
              <p
                className={cx(
                  'about-page-text text-left',
                  index === 0 ? 'mt-2' : 'mt-1',
                )}
              >
                {paragraph}
              </p>
            ))}
          </div>

          <div className="section">
            <ul className="about-page-links">
              {linkItems.map((item) => (
                <li key={item.href} className="about-page-link-item">
                  <a href={item.href} rel="noopener noreferrer" target="_blank">
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
