import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction } from '@core/shared/useAction'
import { initAppAction } from '@core/app/app/actions'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { isAppReady } from '@core/app/app/selectors'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { SessionTimeout } from '@core/components/app/SessionTimeout'

import ProtectedApp from './ProtectedApp'
import PublicApp from './PublicApp'

const App = () => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const isReady = useSelector(isAppReady)
  const init = useAction(initAppAction)

  useEffect(() => {
    init()
  }, [init])

  if (!isReady) {
    return <CircularLoader />
  }

  return (
    <div className="App">
      {isLoggedIn ? (
        <SessionTimeout>
          <ProtectedApp />
        </SessionTimeout>
      ) : (
        <PublicApp />
      )}
    </div>
  )
}

export default App
