import React from 'react'
import clsx from 'clsx'
import styles from './CollapsiblePanel.module.scss'
import { AlertIcon } from '../icons/AlertIcon'

export enum VariantEnum {
  alert = 'alert',
  advice = 'advice',
  normal = 'normal',
}

type Props = {
  title: string
  type?: keyof typeof VariantEnum
  isOpen?: boolean
}

export const CollapsiblePanel: React.FC<Props> = ({
  title,
  children,
  type,
  isOpen = false,
}) => {
  return (
    <details
      className={clsx(styles.accordion, type && styles[type])}
      open={isOpen}
    >
      <summary>
        {title}
        {type === 'alert' && <AlertIcon />}
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  )
}
