import { ReactNode } from 'react'

import { PageFooter } from '../../app/PageFooter'
import { PageHeader } from '../../app/PageHeader'
import { SideMenu } from '../../app/SideMenu'

type Props = {
  title: string
  withBackBtn?: boolean
  children: ReactNode
}
export const GeneralLayout = (props: Props) => {
  const { title, withBackBtn = true, children } = props
  return (
    <div className="page page-general">
      <PageHeader showBg backBtn={withBackBtn} title={title} />
      <div className="page-wrapper">
        <SideMenu />
        <main role="main" className="page-content">
          {children}
        </main>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
