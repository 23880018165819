import styles from './ArrowCounterClockwiseIcon.module.scss'

export const ArrowCounterClockWiseIcon = () => {
  return (
    <svg
      width="24"
      className={styles.icon}
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M7.484 9.348h-4.5v-4.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.166 17.834a8.25 8.25 0 1 0 0-11.668L2.984 9.348"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
