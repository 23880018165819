import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const AlertIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      className={cx('alert-icon', className)}
      role="img"
    >
      <title>Alert</title>
      <g clipPath="url(#a)">
        <path
          d="M9.654 3a.4.4 0 0 1 .692 0l6.582 11.4a.4.4 0 0 1-.346.6H3.418a.4.4 0 0 1-.346-.6L9.654 3Z"
          stroke="#F4616B"
          strokeWidth="1.6"
        />
        <path
          d="m10.781 5.99-.16 4.937H9.447l-.166-4.936h1.5Zm-1.538 6.465c0-.208.072-.38.215-.517a.8.8 0 0 1 .576-.21c.241 0 .433.07.576.21.144.137.215.31.215.517a.69.69 0 0 1-.214.513c-.144.14-.336.21-.577.21a.8.8 0 0 1-.576-.21.69.69 0 0 1-.215-.513Z"
          fill="#F4616B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
