import React from 'react'
import styles from './Conclusion.module.scss'
import cx from 'clsx'

enum variantEnum {
  normal = 'normal',
  advice = 'advice',
  alert = 'alert',
}

type Props = {
  children: React.ReactNode
  dataTestid?: string
  type: keyof typeof variantEnum
}

export const Conclusion: React.FC<Props> = (props) => {
  const { children, dataTestid, type } = props
  return (
    <div
      className={cx(styles.conclusion, styles[type])}
      data-testid={dataTestid}
    >
      {children}
    </div>
  )
}
