import { useEffect, useState, FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import { FilePickerList } from '@core/components/form/FilePickerList'
import { GeneralLayout } from '@core/components/layouts/general'
import { acceptedFileFormats } from '@core/shared/acceptedFileFormats'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Banner } from '@core/components/app/Banners/banner/Banner'
import {
  updateBooking as updateBookingAction,
  getUpcomingBookings as getUpcomingBookingsAction,
} from '@core/app/bookings/actions'
import { upcomingBookings as upcomingBookingsAction } from '@core/app/bookings/selectors'
import { Button } from '@core/components/Button/Button'
import { useAction, useActionAsync } from '@core/shared/useAction'
import { selectAppUser } from '@core/app/user/selectors'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { ErrorIcon } from '@core/components/icons/ErrorIcon'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { BookingType } from '@core/shared/types/bookings'
import styles from './BookingConfirmed.module.scss'
import { datadogLogs } from '@datadog/browser-logs'
import { useFeatures } from '@core/components/app/features/features'

export const BookingConfirmedPage: FunctionComponent = () => {
  const history = useHistory()
  const { bookingId } = useParams<{ bookingId: string }>()
  const updateBooking = useActionAsync(updateBookingAction)
  const showNotification = useAction(showNotificationAction)
  const getUpcomingBookings = useActionAsync(getUpcomingBookingsAction)
  const user = useSelector(selectAppUser)
  const [selectedBooking, setSelectedBooking] = useState<BookingType | null>(
    null,
  )
  const [localError, setLocalError] = useState<string>('')
  const [isLoading, setLoading] = useState(true)
  const upcomingBookings = useSelector(upcomingBookingsAction)

  const { bookings } = useFeatures()
  const canUploadSupportingDocuments =
    bookings.uploadSupportingDocuments.isEnabled

  // no bookingId means the user has not just completed a booking
  if (!bookingId) {
    history.push(ScreenRoutes.DASHBOARD)
  }

  useEffect(() => {
    if (!upcomingBookings || !bookingId) {
      return
    }
    const booking = upcomingBookings.find(
      (el) => el.relationships.appointment.data.id === bookingId,
    )
    booking && setSelectedBooking(booking)
  }, [bookingId, upcomingBookings])

  useEffect(() => {
    const fetchUpcomingBookings = async () => {
      try {
        if (user) {
          await getUpcomingBookings({ userId: user?.id })
          setLoading(false)
        }
      } catch (error) {
        datadogLogs.logger.error(error as string)
        setLoading(false)
      }
    }
    fetchUpcomingBookings()
  }, [getUpcomingBookings, user])

  const { handleSubmit, register, setValue, watch } = useForm()

  useEffect(() => {
    register({ name: 'files' })
  }, [register])

  const goToDashboard = () => history.push(ScreenRoutes.DASHBOARD)

  const onSubmit = async (data: any) => {
    setLocalError('')
    try {
      if (selectedBooking && user) {
        await updateBooking({
          userId: user.id,
          bookingId: selectedBooking.id,
          files: data.files,
        })
        showNotification({
          type: 'success',
          title: 'Consultation updated',
        })
        history.push(ScreenRoutes.DASHBOARD)
      }
    } catch (error) {
      datadogLogs.logger.error(error as string)
      setLocalError(error as string)
    }
  }

  const watchFiles = watch('files')

  return (
    <GeneralLayout title="Booking confirmed">
      <div className="section">
        <Banner
          type="success"
          header="Booking confirmed"
          testid="booking-confirmed"
        />
      </div>
      {canUploadSupportingDocuments ? (
        <div className="section">
          <h2 className="text-heading-2 mb-2">Supporting documents</h2>
          <div className={styles.uploadFiles}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FilePickerList
                onChange={(files) => setValue('files', files)}
                label="Add supporting documents, this could be an image, test results, x-rays etc."
                btnText="Add file"
                acceptFormat={acceptedFileFormats}
              />
              {localError ? (
                <div className="validation-errors flex-center mt-2 mb-2">
                  <ErrorIcon />
                  <div className="tooltip warning ml-1">
                    {localError || 'Something went wrong, please try again.'}
                  </div>
                </div>
              ) : null}
              {watchFiles && (
                <button type="submit" className="btn btn-primary mb-2 mt-2">
                  Upload files
                </button>
              )}
            </form>
          </div>
        </div>
      ) : null}
      <div className="section">
        <hr className="mb-2" />
        <div className="flex-center">
          <Button
            label="Go to dashboard"
            type="primary"
            onClick={goToDashboard}
            className="align-center"
          />
        </div>
      </div>

      {isLoading ? <CircularLoader /> : null}
    </GeneralLayout>
  )
}
