import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { InfoIcon } from '@core/components/icons/InfoIcon'
import { Modal } from '@core/components/Modal/Modal'

interface Props {
  explanation: any
  messageLevel: number
  id: any
}

export const InfoModal: React.FC<Props> = (props) => {
  const { explanation, messageLevel, id } = props

  const [displayModal, setDisplayModal] = useState(false)
  const [modalContent, setModalContent] = useState('')

  const openModal = (copy: string) => {
    setModalContent(copy)
    setDisplayModal(!displayModal)
  }

  const closeModal = () => setDisplayModal(!displayModal)

  const transformHTML = (node: any) => {
    if (node.type === 'text') {
      return <p>{node.data}</p>
    }
  }

  if (!explanation?.length) {
    return null
  }

  return (
    <>
      <span
        style={{ display: 'inline-block' }}
        onClick={() => openModal(explanation)}
      >
        <InfoIcon type={messageLevel === 3 ? 'positive' : 'alert'} clickable />
      </span>
      {displayModal && (
        <Modal title="Explanation" toggleModal={closeModal} key={`modal=${id}`}>
          {ReactHtmlParser(modalContent, {
            transform: transformHTML,
          })}
        </Modal>
      )}
    </>
  )
}
