import { Button } from '../Button/Button'
import { useHistory } from 'react-router-dom'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import styles from './GenericError.module.scss'

export const GenericError = () => {
  const history = useHistory()
  const goToDashboard = () => history.push(ScreenRoutes.DASHBOARD)

  return (
    <div className={styles.genericError}>
      <h2 className="text-heading-1 mb-2">Oops, something went wrong.</h2>
      <p className="text-body mb-2">Please try again.</p>
      <Button label="Go to dashboard" type="primary" onClick={goToDashboard} />
    </div>
  )
}
