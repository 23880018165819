import {
  TraversalsService,
  TraversalsResponse,
  flattenTraversal as flatten,
  TraversalRootState,
} from '@doctorlink/traversal-core'
import { env } from '@core/shared/configuration'
import {
  ApiUrls,
  TraversalBaseStore,
  traversalRootReducersMapObject,
  TraversalsBaseServiceSagas,
  TRAVERSAL_SET_BASE_URL,
  TRAVERSAL_POST_REQUEST,
  TRAVERSAL_RESPOND_POST_REQUEST,
  TRAVERSAL_REVISIT_POST_REQUEST,
  TRAVERSAL_PREVIOUS_POST_REQUEST,
  TRAVERSAL_GET_REQUEST,
  traversalRespondPostResponse,
  traversalPostResponse,
  traversalRevisitPostResponse,
  traversalPreviousPostResponse,
  traversalGetResponse,
  TraversalSetBaseUrl,
  TraversalPostRequest,
  TraversalGetRequest,
  TraversalRespondPostRequest,
  TraversalRevisitPostRequest,
  TraversalPreviousPostRequest,
} from '@doctorlink/traversal-redux'

import { call, ForkEffect, takeLatest } from 'redux-saga/effects'

class MyTraversalsService extends TraversalsService {
  constructor(baseUrl: string) {
    super(baseUrl)
    // This is the only reason we are using this custom traversal logic - to enable cookies to be passed in the request
    this.axios.interceptors.request.use(async (config) => {
      config.withCredentials = true
      return config
    })
  }
}

class MyTraversalsServiceSagas extends TraversalsBaseServiceSagas<TraversalsService> {
  constructor(
    controllerBase: string,
    tokenFactory?: () => Promise<string | null>,
  ) {
    const service = new MyTraversalsService(controllerBase)
    super(service, false)

    this.create = this.effect(
      TRAVERSAL_POST_REQUEST,
      service.create,
      (action: TraversalPostRequest) => [action.body],
      (data: TraversalsResponse) => traversalPostResponse(flatten(data)),
    )

    this.respond = this.effect(
      TRAVERSAL_RESPOND_POST_REQUEST,
      service.respond,
      (action: TraversalRespondPostRequest) => [
        action.traversalId,
        action.body,
      ],
      (data: TraversalsResponse) => traversalRespondPostResponse(flatten(data)),
    )

    this.revisit = this.effect(
      TRAVERSAL_REVISIT_POST_REQUEST,
      service.revisit,
      (action: TraversalRevisitPostRequest) => [
        action.traversalId,
        action.body,
      ],
      (data: TraversalsResponse) => traversalRevisitPostResponse(flatten(data)),
    )

    this.previous = this.effect(
      TRAVERSAL_PREVIOUS_POST_REQUEST,
      service.previous,
      (action: TraversalPreviousPostRequest) => [action.traversalId],
      (data: TraversalsResponse) =>
        traversalPreviousPostResponse(flatten(data)),
    )

    this.get = this.effect(
      TRAVERSAL_GET_REQUEST,
      service.get,
      (action: TraversalGetRequest) => [action.traversalId],
      (data: TraversalsResponse) => traversalGetResponse(flatten(data)),
    )

    this.setBaseUrl = takeLatest(
      TRAVERSAL_SET_BASE_URL,
      function* (action: TraversalSetBaseUrl) {
        yield call(service.setBaseUrl, action.baseUrl)
      },
    )

    this.effects = [
      this.setBaseUrl,
      this.create,
      this.respond,
      this.revisit,
      this.previous,
      this.get,
      this.getQuestions,
      this.getConclusions,
      this.getConclusionReport,
    ]

    this.service = service
  }
  public service: TraversalsService

  public create: ForkEffect<never>
  public respond: ForkEffect<never>
  public revisit: ForkEffect<never>
  public previous: ForkEffect<never>
  public get: ForkEffect<never>
  public setBaseUrl: ForkEffect<never>
}

class MyTraversalStore extends TraversalBaseStore<
  TraversalRootState,
  MyTraversalsService
> {
  constructor(
    urls: ApiUrls,
    moreEffects?: ForkEffect<never>[],
    tokenFactory?: () => Promise<string | null>,
  ) {
    const traversalSagas = new MyTraversalsServiceSagas(
      urls.engine,
      tokenFactory,
    )
    super(
      traversalSagas,
      traversalRootReducersMapObject,
      urls,
      moreEffects,
      tokenFactory,
    )
  }
}

const urls = { engine: env().api.traversalEngineApi }
const traversalStoreHack = new MyTraversalStore(urls)
export default traversalStoreHack
