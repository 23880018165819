import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import {
  TraversalStore,
  traversalPostRequest,
} from '@doctorlink/traversal-redux'
import { TraversalApp, createTheme } from '@doctorlink/styled-components'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Card } from '@core/components/card/Card'
import { Conclusion } from '@core/components/Conclusion/Conclusion'
import { Button } from '@core/components/Button/Button'
import { TertiaryButtonLink } from '@core/components/Button/TertiaryButtonLink'

import { ReportPanel } from './components/ReportPanel'
import { ConclusionsPanel } from './components/ConclusionsPanel'
import {
  createAssessmentAction,
  deleteAssessmentAction,
  setPopulateReasonForBookingAction,
} from '@core/app/symptomsAssessment/actions'
import { useAction } from '@core/shared/useAction'
import { route } from '@core/shared/route'
import { OtherConlusions } from './components/OtherConclusions'
import { ArrowCounterClockWiseIcon } from '@core/components/icons/ArrowCounterClockwiseIcon'
import { CircularLoader } from '@core/components/app/CircularLoader'
import {
  selectAdditionalText,
  selectAssessmentResult,
  selectBookingRecommended,
} from '@core/app/symptomsAssessment/selectors'
import { useSelector } from 'react-redux'
import {
  selectUserPatientAge,
  selectUserPatientGender,
} from '@core/app/patientRecord/selectors'
import { useFeatures } from '@core/components/app/features/features'
import { InjectedHistory } from '@doctorlink/traversal-core'
import { BFFConfigType, env } from '@core/shared/configuration'
import traversalStoreHack from './customTraversal'

const config = env()
const bffDisabled = config.auth.bff === BFFConfigType.Disabled
type SymptomAssessmentParams = {
  shortCode: string
  injectedHistory: InjectedHistory[]
}

const CLIENT_ID = '7ea777ec8d3d43b3b3200f8b70f9651a'
const CLIENT_SECRET = '83fab742341b4cd6815a0382e72bcde8'
const SHORTCODE = 'SC'

const getAccessToken = async () => {
  const myHeaders = new Headers()
  myHeaders.append('content-type', 'application/x-www-form-urlencoded')

  const urlencoded = new URLSearchParams()
  urlencoded.append('grant_type', 'client_credentials')
  urlencoded.append('client_id', CLIENT_ID)
  urlencoded.append('client_secret', CLIENT_SECRET)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  }

  const response = await fetch(
    'https://platform-identity.doctorlink.com/connect/token',
    requestOptions,
  )
  const result = await response.json()
  return result.access_token
}

const defaultTraversalStore = new TraversalStore(
  {
    engine: config.api.traversalEngineApi,
  },
  [],
  () => getAccessToken(),
)
// We need a custom traversalStoreHack approach for clients accessing the traversal via the BFF
// to allow a cookie to be passed to request (cookie demonstrates user is logged in)
const traversalStore = bffDisabled ? defaultTraversalStore : traversalStoreHack

const conclusionSeverity = (severity: number) => {
  if (severity === 1) {
    return 'alert'
  } else if (severity === 2) {
    return 'advice'
  } else if (severity === 3) {
    return 'normal'
  } else {
    return 'normal'
  }
}

const theme = createTheme({
  colors: {
    brand50: '#8CDBC9',
    brand100: '#242D38;',
    brand200: '#F4C2C2',
    lightBlue100: '#C0CFE9',
  },
  typography: {
    fontFamily: "'Lato','Roboto', 'sans-serif'",
  },
  spacing: {
    vertical: 25,
  },
})

export const SymptomAssessment = () => {
  const history = useHistory()

  const [isLoading, setLoading] = useState<boolean>(false)
  const report = useSelector(selectAssessmentResult)
  const age = useSelector(selectUserPatientAge)
  const gender = useSelector(selectUserPatientGender)
  const storeAssessment = useAction(createAssessmentAction)
  const deleteAssessment = useAction(deleteAssessmentAction)
  const setPopulateReasonForBooking = useAction(
    setPopulateReasonForBookingAction,
  )
  const { dependants } = useFeatures()

  const displayBookButton = useSelector(selectBookingRecommended)
  const additionalText = useSelector(selectAdditionalText)

  const saGender = gender === 'Female' ? 89 : 88

  const params: SymptomAssessmentParams = {
    shortCode: SHORTCODE,
    injectedHistory: [
      {
        assetId: 70,
        answerId: saGender,
        injectedHistoryType: 'prepopulate',
        isAnswered: true,
      },
      {
        assetId: 19063,
        answerId: 16309,
        value: age?.toString(),
        injectedHistoryType: 'prepopulate',
        isAnswered: true,
      },
    ],
  }

  if (!dependants) {
    params.injectedHistory.push({
      assetId: 13045,
      answerId: 7527,
      injectedHistoryType: 'preanswer',
      isAnswered: true,
    })
  }

  useEffect(() => {
    traversalStore.store.dispatch(traversalPostRequest(params))
  }, [])

  useEffect(() => {
    return traversalStore.store.subscribe(() => {
      const state = traversalStore.store.getState()

      if (state.conclusion?.symptomReport) {
        const conclusion = state.conclusion?.symptomReport
        storeAssessment({ conclusion })
      }
    })
  }, [storeAssessment])

  useEffect(() => {
    if (isLoading) {
      return traversalStore.store.subscribe(() => {
        const state = traversalStore.store.getState()
        // once the traversal stops reloading, we clear our redux state
        // and remove loading
        if (state.traversal.loading === false) {
          deleteAssessment()
          setLoading(false)
        }
      })
    }
  }, [deleteAssessment, isLoading])

  const onBookingClick = () => {
    setPopulateReasonForBooking({ populateReasonForBooking: true })
    history.push(route(ScreenRoutes.BOOK_CONSULTATION, { cpSpecialtiesId: 1 }))
  }

  const onRestartAssessment = () => {
    setLoading(true)
    traversalStore.store.dispatch(traversalPostRequest(params))
  }

  if (isLoading) {
    return <CircularLoader />
  }

  return (
    <div className="page page-symptom-checker">
      <PageHeader showBg backBtn title="Assess symptoms" />

      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <section>
            {report ? (
              <>
                <Conclusion
                  dataTestid="recommendation"
                  type={conclusionSeverity(report.messageLevel)}
                >
                  <h2>{report.messageTitle}</h2>
                </Conclusion>

                <div className="section">
                  <TertiaryButtonLink
                    label="Restart assessment"
                    startIcon={<ArrowCounterClockWiseIcon />}
                    onClick={onRestartAssessment}
                  />
                </div>

                <ConclusionsPanel
                  title="Results"
                  conclusions={report.reasonConclusions}
                  messageLevel={report.messageLevel}
                />

                <div className="section" data-testid="what-we-recommend">
                  <h3 className="section-title mb-1">What we recommend</h3>
                  <Card className={conclusionSeverity(report.messageLevel)}>
                    <p>{ReactHtmlParser(report.messageDescription)}</p>
                    {additionalText && (
                      <p className="mt-1">{ReactHtmlParser(additionalText)}</p>
                    )}
                    {displayBookButton && (
                      <Button
                        onClick={onBookingClick}
                        type="primary"
                        label="Book now"
                      />
                    )}
                  </Card>
                </div>
                <div className="section" data-testid="other-concerns">
                  <OtherConlusions
                    severity={conclusionSeverity(report.messageLevel)}
                    title={report.otherConclusionTitle}
                    messageLevel={report.messageLevel}
                    bullets={report.otherConclusions}
                  />
                </div>

                <div className="section" data-testid="advice">
                  <h3 className="section-title mb-1">Advice</h3>
                  <ReportPanel
                    title={report.dangerBulletTitle}
                    bullets={report.dangerBullets}
                    messageLevel={report.messageLevel}
                    type="alert"
                  />
                  <ReportPanel
                    title={report.contactBulletTitle}
                    type={conclusionSeverity(report.messageLevel)}
                    bullets={report.contactBullets}
                    messageLevel={report.messageLevel}
                  />
                  <ReportPanel
                    title={report.reasonBulletTitle}
                    bullets={report.reasonBullets}
                    messageLevel={report.messageLevel}
                  />
                  <ReportPanel
                    title={report.informationConclusionTitle}
                    bullets={report.informationConclusions}
                    messageLevel={report.messageLevel}
                  />
                </div>
              </>
            ) : (
              <div className="section doctorlink-wrapper" id="traversal">
                <TraversalApp store={traversalStore.store} theme={theme} />
              </div>
            )}
          </section>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
